/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-10-17 10:18:14
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2023-03-29 10:35:26
 * @FilePath: /webapp/hoosk/useLoginInfo.js
 * @Description: useLoginInfo
 */
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce } from 'react-use';
import { 
    checkAdminLogin,
    login as adminLoginAction,
    logout as adminLogoutAction 
} from '../../services/adminServices';
import {
    checkAFMemberLogin,
    login as afMemberLoginAction,
    logout as afMemberLogoutAction,
} from '../../services/memberServices';
import { actions as webActions } from '../../store/webSlice';

const useLoginInfo = () => {

    const dispatch = useDispatch();

    const reduxState = useSelector((state) => ({
        isAdminLogin: state.admin.isLogin,
        isAdminLogining: state.admin.isLogining,
        adminLoginError: state.admin.loginError,
        admin: state.admin.admin,
        isLogin: state.member.isLogin,
        isLogining: state.member.isLogining,
        loginError: state.member.loginError,
        afMember: state.member.afMember
    }));

    const {
        isAdminLogin,
        isAdminLogining,
        adminLoginError,
        admin: adminInfo,
        isLogin,
        isLogining,
        loginError,
        afMember: afMemberInfo
    } = reduxState;

    const getAdminLoginInfo = () => {

        if(isAdminLogin || isLogin)
            return;

        dispatch(checkAdminLogin());
    }

    const getAfMemberLoginInfo = () => {

        if(isAdminLogin || isLogin)
            return;

        dispatch(checkAFMemberLogin());
    }

    const adminLogin = (adminid, password, token) => {

        if(isAdminLogining)
            return;

        dispatch(adminLoginAction({
            adminid: adminid,
            password: password,
            token: token
        }))
    }

    const adminLogout = () => {
        
        dispatch(adminLogoutAction());
    }

    const afMemberLogin = (email, password, token) => {

        if(isLogining)
            return;

        dispatch(afMemberLoginAction({
            email: email,
            password: password,
            token: token
        }))
    }

    const afMemberLogout = () => {
        dispatch(afMemberLogoutAction());
    }

    const logout = () => {

        dispatch(webActions.openConfirmModal({
            title: '登出',
            description: '確認登出？',
            confirmCallback: () => {
                if(isAdminLogin) {
                    adminLogout();
                } else {
                    afMemberLogout();
                }
            }
        }))
    }

    useEffectOnce(() => {
        getAdminLoginInfo();
        getAfMemberLoginInfo();
    })

    return {
        // 管理者相關操作
        isAdminLogin,
        isAdminLogining,
        adminLoginError,
        adminInfo,
        adminLogin, 
        // 推廣者相關
        isLogin,
        isLogining,
        loginError,
        afMemberInfo,
        afMemberLogin,
        // 共用
        logout
    }
}

export default useLoginInfo;