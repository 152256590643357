/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-10-17 12:24:02
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2022-10-31 18:01:15
 * @FilePath: /webapp/store/memberSlice.jsx
 * @Description: 推廣者 Slice
 */
import { createSlice } from "@reduxjs/toolkit";
import { 
    checkAFMemberLogin,
    login,
    logout,
    getAFMemberList,
    getAFMemberInfo,
    createMemebr,
    updateMemebr,
    uploadMemebrPhoto,
    toggleMemberStatus
} from '../services/memberServices';
import { getAFMemberListKey } from '../components/afmember/AfMemberUtil';

// init state
const initialState = {
    isCheckingLogin: false, // 是否正在檢查推廣者登入資訊
    isLogining: false, // 推廣者是否正在登入
    loginError: {
        code: undefined,
        resultMsg: undefined,
        field_message: []
    }, // 管理者登入錯誤訊息
    isLogin: false, // 推廣者是否已經登入
    afMember: undefined, // 推廣者登入資訊
    isLogouting: false, // 是否正在登出中,
    afMemberListCache: { // Member List Cache
        // [listkey]: {
        //      ids: { [index]: afmemberid }
        //      totalCount
        //      isLoading
        //      isError
        // }
    }, 
    afMemberCache: { // Member Info Cache
        // [afmemberid]: afMember
    },
}

// creator
export const adminSlice = createSlice({
    name: 'member',
    initialState: initialState,
    reducers: {
        setAfMemberLoginInfo: (state, { payload }) => {
            state.isLogin = true;
            const afmemberid = payload.afmemberid.trim();
            payload.afmemberid = afmemberid;
            state.afMember = payload;
            state.afMemberCache[afmemberid] = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(checkAFMemberLogin.pending, (state) => {
            state.isCheckingLogin = true;
        })
        builder.addCase(checkAFMemberLogin.fulfilled, (state) => {
            state.isCheckingLogin = false;
        })
        builder.addCase(checkAFMemberLogin.rejected, (state) => {
            state.isCheckingLogin = false;
        })
        builder.addCase(login.pending, (state) => {
            state.isLogining = true;
            state.loginError.code = undefined;
            state.loginError.resultMsg = undefined;
            state.loginError.field_messageMap = undefined;
        })
        builder.addCase(login.fulfilled, (state) => {
            state.isLogining = false;
        })
        builder.addCase(login.rejected, (state, { payload }) => {
            state.isLogining = false;
            if(payload) {
                state.loginError.code = payload.code;
                state.loginError.resultMsg = payload.resultMsg;
                const field_message = [];
                if(payload.field_messageMap) {
                    for(let key in payload.field_messageMap) {
                        field_message.push(payload.field_messageMap[key]);
                    }
                }
                state.loginError.field_message = field_message;
            }
        })
        builder.addCase(logout.pending, (state) => {
            state.isLogouting = true;
        })
        builder.addCase(logout.fulfilled, (state) => {
            state.isLogouting = false;
            state.isLogin = false;
            state.afMember = undefined;
        })
        builder.addCase(logout.rejected, (state, result) => {
            state.isLogouting = false;
        })

        builder.addCase(getAFMemberList.pending, (state, { meta, payload }) => {

            const listKey = getAFMemberListKey(meta.arg);

            let afMemberList = state.afMemberListCache[listKey];
            if(!afMemberList) {
                afMemberList = {
                    isLoading: true
                }
            } else {
                afMemberList.isLoading = true;
            }
            state.afMemberListCache[listKey] = afMemberList;
        })
        builder.addCase(getAFMemberList.fulfilled, (state, { meta, payload }) => {

            const listKey = getAFMemberListKey(meta.arg);

            const {
                start, len
            } = meta.arg;

            const afmembers = Array.isArray(payload.resultData) ? payload.resultData : [];
            const totalCount = payload.totalCount;

            let afMemberList = state.afMemberListCache[listKey];
            if(!afMemberList) {
                afMemberList = {
                    isLoading: false,
                    isEmpty: false
                }
            } else {

                const ids = afMemberList.ids ? afMemberList.ids : {};
                for(let i = 0 ; i < len ; i++) {
                    const afmember = afmembers[i];

                    let index = start + i;
                    if(!afmember) {
                        delete ids[index]; // 移除不存在的內容
                        continue;
                    } 

                    const afmemberid = afmember.afmemberid.trim();
                    afmember.afmemberid = afmemberid;
                    ids[index] = afmemberid
                    state.afMemberCache[afmemberid] = afmember;
                }

                afMemberList.ids = ids;
                afMemberList.isLoading = false;
                afMemberList.isError = false;
                afMemberList.isEmpty = Object.keys(ids).length === 0;
                if(totalCount) {
                    afMemberList.totalCount = totalCount;
                }
            }

            state.afMemberListCache[listKey] = afMemberList;
        })
        builder.addCase(getAFMemberList.rejected, (state, { meta, payload }) => {

            const listKey = getAFMemberListKey(meta.arg);

            let afMemberList = state.afMemberListCache[listKey];
            if(!afMemberList) {
                afMemberList = {
                    isLoading: false,
                    isError: true
                }
            } else {
                afMemberList.isLoading = false;
                afMemberList.isError = true;
            }

            state.afMemberListCache[listKey] = afMemberList;
        })

        builder.addCase(getAFMemberInfo.fulfilled, (state, { payload }) => {

            const afmember = payload.resultData;
            const afmemberid = afmember.afmemberid.trim();
            afmember.afmemberid = afmemberid;
            state.afMemberCache[afmemberid] = afmember;
        })

        builder.addCase(createMemebr.fulfilled, (state, { payload }) => {

            const afmember = payload.resultData;
            const afmemberid = afmember.afmemberid.trim();
            afmember.afmemberid = afmemberid;
            state.afMemberCache[afmemberid] = afmember;
        })
        builder.addCase(updateMemebr.fulfilled, (state, { payload }) => {

            const afmember = payload.resultData;
            const afmemberid = afmember.afmemberid.trim();
            afmember.afmemberid = afmemberid;
            state.afMemberCache[afmemberid] = afmember;
        })
        builder.addCase(uploadMemebrPhoto.fulfilled, (state, { meta, payload }) => {
            const { afmemberid } = meta.arg;
            const photolink = payload.resultData;
            const afmember = state.afMemberCache[afmemberid];
            if(afmember) {
                afmember.photolink = photolink;
                state.afMemberCache[afmemberid] = afmember;
            }
        })
        

        builder.addCase(toggleMemberStatus.fulfilled, (state, { meta }) => {

            const {
                afmemberid,
                status
            } = meta.arg;

            if(state.afMemberCache[afmemberid]) {
                state.afMemberCache[afmemberid].status = status;
            }
        })
    }
});

// actions
export const actions = adminSlice.actions;

// reducer
export default adminSlice.reducer;