/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-10-19 14:44:27
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2022-10-19 16:24:28
 * @FilePath: /webapp/components/afmember/AfMemberUtil.jsx
 * @Description: AFMember相關元件
 */

// 取得 List 對應的 Key
export const getAFMemberListKey = (payload) => {

    const {
        keyword,
        start_yyyymmdd,
        end_yyyymmdd,
    } = payload;

    let listKey = '';

    if(keyword) 
        listKey += `keyword-${keyword}`;
    if(start_yyyymmdd) 
        listKey += `start_yyyymmdd-${start_yyyymmdd}`;
    if(end_yyyymmdd) 
        listKey += `end_yyyymmdd-${end_yyyymmdd}`;

    if(!listKey)
        return "ALL";
    
    return listKey;
}