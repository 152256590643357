/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-07-28 16:23:45
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2023-03-29 10:28:46
 * @FilePath: /CupoyFrontend_NextJS/store/actions/memberActions.ts
 * @Description: 會員 相關 API 呼叫 method
 */
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from '../components/global';
import { actions as memberActions } from '../store/memberSlice';

// 檢查管理者是否有登入
export const checkAFMemberLogin = createAsyncThunk(
    "/member/checkLogin",
    async (
        arg,    
        thunkAPI
    ) => {

        try {
            const url = `${API_URL}member/checkLogin`;
            const response = await axios({
                method: 'GET',
                url: url,
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            thunkAPI.dispatch(memberActions.setAfMemberLoginInfo(data.resultData));

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 推廣者登入
export const login = createAsyncThunk(
    "/member/login",
    async ({
        email,
        password,
        token
    }, thunkAPI) => {

        try {
            const url = `${API_URL}member/login`;
            const response = await axios({
                method: 'POST',
                url: url,
                data: {
                    email,
                    password,
                    token
                },
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }
            
            thunkAPI.dispatch(checkAFMemberLogin());

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 推廣者登出
export const logout = createAsyncThunk(
    "/member/logout",
    async (arg, thunkAPI) => {

        try {
            const url = `${API_URL}member/logout`;
            const response = await axios({
                method: 'POST',
                url: url,
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 取得單一個推廣者的資訊
export const getAFMemberInfo = createAsyncThunk(
    "/member/getAFMemberInfo",
    async (
        afmemberid,    
        thunkAPI
    ) => {

        try {
            const url = `${API_URL}member/get/${afmemberid}`;
            const response = await axios({
                method: 'GET',
                url: url,
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 取得推廣者的列表
export const getAFMemberList = createAsyncThunk(
    "/member/getAFMemberList",
    async ({
        keyword,
        start_yyyymmdd,
        end_yyyymmdd,
        start = 0,
        len = 20
    }, thunkAPI) => {

        try {
            
            let url = `${API_URL}member/query?start=${start}&len=${len}`;

            if (keyword) url += `&keyword=${keyword}`;
            if (start_yyyymmdd) url += `&start_yyyymmdd=${start_yyyymmdd}`;
            if (end_yyyymmdd) url += `&end_yyyymmdd=${end_yyyymmdd}`;

            const response = await axios({
                method: 'GET',
                url: url,
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 建立推廣者
export const createMemebr = createAsyncThunk(
    "/member/createMemebr",
    async ({
        membername,
        email,
        phone,
        password,
        remark,
        shareratio
    }, thunkAPI) => {

        try {
            const url = `${API_URL}member/create`;
            const response = await axios({
                method: 'POST',
                url: url,
                headers: { "Content-Type": "multipart/form-data" },
                data: {
                    membername,
                    email,
                    phone,
                    password,
                    remark,
                    shareratio
                },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 更新推廣者資訊
export const updateMemebr = createAsyncThunk(
    "/member/updateMemebr",
    async ({
        afmemberid,
        membername,
        email,
        phone,
        remark,
        shareratio
    }, thunkAPI) => {

        try {
            const url = `${API_URL}member/update`;
            const response = await axios({
                method: 'POST',
                url: url,
                headers: { "Content-Type": "multipart/form-data" },
                data: {
                    afmemberid,
                    membername,
                    email,
                    phone,
                    remark,
                    shareratio
                },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 更新推廣者密碼
export const updateMemberPassword = createAsyncThunk(
    "/member/updateMemberPassword",
    async ({
        afmemberid,
        password,
        oldPassword
    }, thunkAPI) => {

        try {
            const url = `${API_URL}member/updatePassword`;
            const response = await axios({
                method: 'POST',
                url: url,
                headers: { "Content-Type": "multipart/form-data" },
                data: {
                    afmemberid,
                    password,
                    oldPassword
                },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 上傳推廣者大頭照
export const uploadMemebrPhoto = createAsyncThunk(
    "/member/uploadMemebrPhoto",
    async ({
        afmemberid,
        photo
    }, thunkAPI) => {

        try {
            const url = `${API_URL}member/uploadPhoto`;
            const response = await axios({
                method: 'POST',
                url: url,
                headers: { "Content-Type": "multipart/form-data" },
                data: {
                    afmemberid,
                    photo
                },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)


// 推廣者登入
export const toggleMemberStatus = createAsyncThunk(
    "/member/toggleStatus",
    async ({
        afmemberid,
        status,
    }, thunkAPI) => {

        try {
            const url = `${API_URL}member/toggle/${afmemberid}/${status}`;
            const response = await axios({
                method: 'POST',
                url: url,
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }
            
            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)
