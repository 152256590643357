/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-11-02 11:45:21
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2023-03-03 12:31:37
 * @FilePath: /nonnafweb/src/RootView.jsx
 * @Description: RootView
 */

import styled from "styled-components";
import { useSelector } from "react-redux";
import { useMatch } from 'react-router-dom';
import { Routes, Route } from "react-router-dom";
import loadable from '@loadable/component';
import { RoutePaths } from "./components/route/@type/routes.d";
import TopBar from './components/commons/TopBar';
import Sidebar from "./components/commons/SideBar";
import LoginView from "./components/commons/LoginView";
import useLoginInfo from './hoosk/admin/useLoginInfo';

/**
 * Lazy Components
 */
const HomeView = loadable(() => import('./container/HomeView'));
const AdminLoginView = loadable(() => import('./container/admin/AdminLoginView'));
const AdminListView = loadable(() => import('./container/admin/AdminListView'));
const AddAdminView = loadable(() => import('./container/admin/AddAdminView'));
const EditAdminView = loadable(() => import('./container/admin/EditAdminView'));
const AFMemberListView = loadable(() => import('./container/afmember/AFMemberListView'));
const AddAFMemberView = loadable(() => import('./container/afmember/AddAFMemberView'));
const EventListView = loadable(() => import('./container/event/EventListView'));
const AddEventView = loadable(() => import('./container/event/AddEventView'));
const EditEventView = loadable(() => import('./container/event/EditEventView'));
const EditEventProductView = loadable(() => import('./container/event/EditEventProductView'));
const AfMemberNavView = loadable(() => import('./container/afmember/AfMemberNavView'));
const AFMemberEditView = loadable(() => import('./container/afmember/AFMemberEditView'));
const SalesLogListView = loadable(() => import('./container/SalesLogListView'));
const SalesReportListView = loadable(() => import('./container/SalesReportListView'));
const EventProductsView = loadable(() => import('./container/event/EventProductsView'));

/**
 * Modals
 */
const AlertModal = loadable(() => import('./components/modals/AlertModal'));
const ConfirmModal = loadable(() => import('./components/modals/ConfirmModal'));
const PreviewEventProductsModal = loadable(() => import('./components/modals/PreviewEventProductsModal'));

/**
 * Styles
 */
const Container = styled.div`
    width: 100%;
`;
const Content = styled.div`
    padding: 60px 0 0 ${props => props.isAdmin ? '240px' : 0};
`;

const RootView = () => {

    const reduxState = useSelector((state) => ({
        alertModal: state.web.alertModal,
        confirmModal: state.web.confirmModal,
        previewEventModal: state.event.previewEventModal,
    }));

    const {
        confirmModal,
        alertModal,
        previewEventModal
    } = reduxState;

    const {
        isAdminLogin,
        isLogin
    } = useLoginInfo();

    const isOnAdminLoginView = !!useMatch(RoutePaths.ADMIN_LOGIN);
    const isOnEventProductView = !!useMatch(RoutePaths.EVENT_PRODUCTS);

    return (
        <>
            {isOnAdminLoginView && (
                <Routes>
                    <Route path={RoutePaths.ADMIN_LOGIN} element={<AdminLoginView />} />
                </Routes>
            )}

            {isOnEventProductView && (
                <Routes>
                    <Route path={RoutePaths.EVENT_PRODUCTS} element={<EventProductsView />} />
                </Routes>
            )}

            {(isOnAdminLoginView || isOnEventProductView) ?
                null
                :
                !isAdminLogin && !isLogin ?
                    <LoginView />
                    :
                    <Container>

                        <TopBar />

                        {isAdminLogin && <Sidebar />}

                        <Content isAdmin={isAdminLogin}>
                            <Routes>

                                <Route path={RoutePaths.AFMEMBER_EDIT} element={<AFMemberEditView />} />
                                <Route path={RoutePaths.AFMEMBER} element={<AfMemberNavView />}>
                                    <Route path="*" element={<AfMemberNavView />} />
                                </Route>

                                {isAdminLogin && (
                                    <>
                                        {/* 只有 Admin 登入的頁面可以看到 */}
                                        <Route path={RoutePaths.AFMEMBERLIST} element={<AFMemberListView />} />
                                        <Route path={RoutePaths.ADDAFMEMBER} element={<AddAFMemberView />} />
                                        
                                        <Route path={RoutePaths.ADMIN} element={<AdminListView />} />
                                        <Route path={RoutePaths.ADDNEWADMIN} element={<AddAdminView />} />
                                        <Route path={RoutePaths.UPDATEADMIN} element={<EditAdminView />} />
                                        
                                        <Route path={RoutePaths.EVENTS} element={<EventListView />} />
                                        <Route path={RoutePaths.ADDNEWEVENT} element={<AddEventView />} />
                                        <Route path={RoutePaths.EDITEVENT} element={<EditEventView />} />
                                        <Route path={RoutePaths.EDITEVENT_PRODUCTS} element={<EditEventProductView />} />

                                        <Route path={RoutePaths.AFSALESQUERYLOG} element={<SalesLogListView />} />
                                        <Route path={RoutePaths.AFSALESQUERYREPORT} element={<SalesReportListView />} />    
                                    </>
                                )}
                                
                                <Route path={RoutePaths.HOME} element={<HomeView />} />
                                <Route path="*" element={<HomeView />} />
                            </Routes>
                        </Content>
                    </Container>
            }


            {confirmModal.isOpen && <ConfirmModal /> }
            {alertModal.isOpen && <AlertModal />}
            {previewEventModal.isOpen && <PreviewEventProductsModal />}
        </>
    );
}

export default RootView;