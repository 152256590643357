/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-10-11 15:02:04
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2022-10-18 10:58:56
 * @FilePath: /Nonn_AFF2E/store/webSlice.jsx
 * @Description: Web Slice
 */

import { createSlice } from "@reduxjs/toolkit";

// init state
const initialState = {
    alertModal: {
        isOpen: false,
        title: '',
        description: ''
    },
    confirmModal: {
        isOpen: false,
        title: '',
        isPromise: false,
        needRemark: false,
    }
}

// creator
export const webSlice = createSlice({
    name: 'web',
    initialState: initialState,
    reducers: {

        openAlertModal: (state, { payload }) => {

            state.alertModal.isOpen = true;
            state.alertModal.title = payload.title;
            state.alertModal.description = payload.description;
            state.alertModal.confirmName = payload.confirmName;
            state.alertModal.callback = payload.callback;
            state.alertModal.isError = payload.isError;
            state.alertModal.iconComponent = payload.iconComponent;
        },
        closeAlertModal: (state) => {

            state.alertModal.isOpen = false;
        },
        openConfirmModal: (state, { payload }) => {

            state.confirmModal.isOpen = true;

            state.confirmModal.title = payload.title;
            state.confirmModal.description = payload.description;
            state.confirmModal.isPromise = payload.isPromise;
            state.confirmModal.confirmCallback = payload.confirmCallback;
            state.confirmModal.confirmPromise = payload.confirmPromise;
            state.confirmModal.cancelCallback = payload.cancelCallback;
            state.confirmModal.confirmName = payload.confirmName;
            state.confirmModal.confirmSeconds = payload.confirmSeconds;
            state.confirmModal.disableCancel = payload.disableCancel;
            state.confirmModal.descColor = payload.descColor;
            state.confirmModal.needRemark = payload.needRemark;
            state.confirmModal.remarkPlaceholder = payload.remarkPlaceholder;
        },
        closeConfirmModal: (state) => {

            state.confirmModal.isOpen = false;
        }
    },
    extraReducers: {

    }
});

// actions
export const actions = webSlice.actions;

// reducer
export default webSlice.reducer;