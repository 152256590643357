/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-10-11 14:51:35
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2022-10-27 16:00:25
 * @FilePath: /Nonn_AFF2E/components/commons/SideBar.jsx
 * @Description: 網站側邊欄
 */

import { Accordion } from '@mantine/core';
import React from "react";
import styled from "styled-components";
import { RoutePaths } from "../route/@type/routes.d";
import RouteLink from "../route/RouteLink";

const Container = styled.nav`
    position: fixed;
    width: 240px;
    top: 60px;
    height: 100%;
    padding: 20px 10px;
    background-color: #fff;
    z-index: 90;
    overflow: auto;
    border-right: 1px solid #EEE;
    .mantine-Accordion-item {
        border-bottom: 1px solid #707070;
    }
`;
const CategoryTitle = styled.div`
    font-size: 18px;
    font-weight: 500;
    color: #404040;
`;
const LinkItem = styled.div`
    font-size: 16px;
    line-height: 2em;
    color: #707070;
    cursor: pointer;
`;

const SideBar = () => {
    
    return (
        <Container>
            <Accordion defaultValue="afmember">
                <Accordion.Item value="afmember">
                    <Accordion.Control>
                        <CategoryTitle>
                            推廣者管理
                        </CategoryTitle>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <RouteLink path={RoutePaths.AFMEMBERLIST}>
                            <LinkItem>
                                推廣者管理
                            </LinkItem>
                        </RouteLink>
                        <RouteLink path={RoutePaths.ADDAFMEMBER}>
                            <LinkItem>
                                新增推廣者
                            </LinkItem>
                        </RouteLink>
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="event">
                    <Accordion.Control>
                        <CategoryTitle>
                            推廣活動管理
                        </CategoryTitle>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <RouteLink path={RoutePaths.EVENTS}>
                            <LinkItem>
                                推廣活動管理
                            </LinkItem>
                        </RouteLink>
                        <RouteLink path={RoutePaths.ADDNEWEVENT}>
                            <LinkItem>
                                新增推廣活動
                            </LinkItem>
                        </RouteLink>
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="afsales">
                    <Accordion.Control>
                        <CategoryTitle>
                            對帳分潤
                        </CategoryTitle>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <RouteLink path={RoutePaths.AFSALESQUERYLOG}>
                            <LinkItem>    
                                銷售明細表
                            </LinkItem>
                        </RouteLink>
                        <RouteLink path={RoutePaths.AFSALESQUERYREPORT}>
                            <LinkItem>                            
                                分潤對帳表
                            </LinkItem>
                        </RouteLink>
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="admin">
                    <Accordion.Control>
                        <CategoryTitle>
                            管理者管理
                        </CategoryTitle>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <RouteLink path={RoutePaths.ADMIN}>
                            <LinkItem>
                                管理者管理
                            </LinkItem>
                        </RouteLink>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </Container>
    );
};

export default SideBar;
