/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-10-13 16:31:32
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2023-02-07 17:24:06
 * @FilePath: /webapp/services/eventServices.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from '../components/global';
import { EVENT_LIST_TYPE } from '../components/event/AFEventUtil';

// 建立活動
export const createEvent = createAsyncThunk(
    "/event/createEvent",
    async ({
        eventid,
        eventname,
        description,
        expiretime
    }, thunkAPI) => {

        try {

            const url = `${API_URL}afevent/create`;
            const response = await axios({
                method: 'POST',
                url: url,
                data: {
                    eventid,
                    eventname,
                    description,
                    expiretime
                },
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 取得活動的列表
export const getEventList = createAsyncThunk(
    "/event/getEventList",
    async ({
        afmemberid,
        listtype,
        keyword,
        start_yyyymmdd,
        end_yyyymmdd,
        start = 0,
        len = 20
    }, thunkAPI) => {

        try {
            
            let url;
            if(afmemberid) {
                if(listtype === EVENT_LIST_TYPE.SUBSCRIBED) {
                    url = `${API_URL}subafevent/qysubscribeevents?afmemberid=${afmemberid}&start=${start}&len=${len}`;
                } else {
                    url = `${API_URL}subafevent/qyunsubscribeevents?afmemberid=${afmemberid}&start=${start}&len=${len}`;
                }
            } else {
                url = `${API_URL}afevent/query?start=${start}&len=${len}`;
                if (keyword) url += `&eventname=${keyword}`;
                if (start_yyyymmdd) url += `&start_yyyymmdd=${start_yyyymmdd}`;
                if (end_yyyymmdd) url += `&end_yyyymmdd=${end_yyyymmdd}`;
            }

            const response = await axios({
                method: 'GET',
                url: url,
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 取得活動資訊
export const getEventInfo = createAsyncThunk(
    "/event/getEventInfo",
    async (eventid, thunkAPI) => {

        try {
            
            let url = `${API_URL}afevent/get/${eventid}`;
            const response = await axios({
                method: 'GET',
                url: url,
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 取得活動商品
export const getEventProducts = createAsyncThunk(
    "/event/getEventProducts",
    async (eventid, thunkAPI) => {

        try {
            
            let url = `${API_URL}afevent/getProducts/${eventid}`;
            const response = await axios({
                method: 'GET',
                url: url,
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 訂閱活動
export const subscribeEvent = createAsyncThunk(
    "/event/subscribeEvent",
    async ({
        afmemberid,
        eventid
    }, thunkAPI) => {

        try {

            const url = `${API_URL}subafevent/subscribe`;
            const response = await axios({
                method: 'POST',
                url: url,
                data: {
                    afmemberid,
                    eventid
                },
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 取消訂閱活動
export const unsubscribeEvent = createAsyncThunk(
    "/event/unsubscribeEvent",
    async ({
        afmemberid,
        eventid
    }, thunkAPI) => {

        try {

            const url = `${API_URL}subafevent/unsubscribe`;
            const response = await axios({
                method: 'POST',
                url: url,
                data: {
                    afmemberid,
                    eventid
                },
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 更新活動
export const updateEvent = createAsyncThunk(
    "/event/updateEvent",
    async ({
        eventid,
        eventname,
        expiretime,
        description
    }, thunkAPI) => {

        try {

            const url = `${API_URL}afevent/update`;
            const response = await axios({
                method: 'POST',
                url: url,
                data: {
                    eventid,
                    eventname,
                    expiretime,
                    description
                },
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 更新活動商品
export const addEventProduct = createAsyncThunk(
    "/event/addEventProduct",
    async ({
        eventid,
        pdtlink,
        pdtname
    }, thunkAPI) => {

        try {

            const url = `${API_URL}afevent/addEventProduct`;
            const response = await axios({
                method: 'POST',
                url: url,
                data: {
                    eventid,
                    pdtlink,
                    pdtname
                },
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)


// 使用爬蟲取得活動商品資訊
export const getEventProduct = createAsyncThunk(
    "/event/getEventProduct",
    async ({
        pdtlink,
        pdtname
    }, thunkAPI) => {

        try {

            const url = `${API_URL}afevent/getEventProduct`;
            const response = await axios({
                method: 'POST',
                url: url,
                data: {
                    pdtlink,
                    pdtname
                },
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)


// 移除活動商品
export const removeEventProduct = createAsyncThunk(
    "/event/removeEventProduct",
    async ({
        eventid,
        pdtname
    }, thunkAPI) => {

        try {

            const url = `${API_URL}afevent/removeEventProduct`;
            const response = await axios({
                method: 'POST',
                url: url,
                data: {
                    eventid,
                    pdtname
                },
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)


// 排序活動商品
export const orderEventProducts = createAsyncThunk(
    "/event/orderEventProducts",
    async ({
        eventid,
        pdtnames
    }, thunkAPI) => {

        try {

            const url = `${API_URL}afevent/orderEventProducts`;
            const response = await axios({
                method: 'POST',
                url: url,
                data: {
                    eventid,
                    pdtnames: pdtnames.join(',')
                },
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)
