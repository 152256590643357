/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-11-02 10:48:10
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2022-11-02 12:01:39
 * @FilePath: /nonnafweb/src/App.js
 * @Description: App
 */
import { store } from './store';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import RootView from './RootView';

const App = () => {

    return (
        <Provider store={store}>
            <BrowserRouter>
                <RootView />
            </BrowserRouter>
        </Provider>
    );
}

export default App;
