/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-10-26 16:26:43
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2023-02-07 17:31:42
 * @FilePath: /webapp/store/eventSlice.jsx
 * @Description: 推廣活動 Slices
 */

import { createSlice } from "@reduxjs/toolkit";
import { 
    getEventList,
    subscribeEvent,
    unsubscribeEvent,
    getEventInfo,
    updateEvent,
    addEventProduct,
    removeEventProduct,
    orderEventProducts
} from '../services/eventServices';
import { getAFEventListKey, EVENT_LIST_TYPE } from '../components/event/AFEventUtil';

// init state
const initialState = {
    eventListCache: { // Event List Cache
        // [listkey]: {
        //      ids: { [index]: eventid }
        //      totalCount
        //      isLoading
        //      isError
        // }
    }, 
    eventCache: { // Event Info Cache
        // [eventid]: AFEvent
    },
    previewEventModal: {
        isOpen: false,
        eventid: undefined
    }
}

// creator
export const eventSlice = createSlice({
    name: 'member',
    initialState: initialState,
    reducers: {
        openPreviewEventModal: (state, { payload }) => {

            state.previewEventModal.isOpen = true;
            state.previewEventModal.eventid = payload.eventid;
        },
        closePreviewEventModal: (state) => {

            state.previewEventModal.isOpen = false;
        },
    },
    extraReducers: (builder) => {
        
        builder.addCase(getEventList.pending, (state, { meta }) => {

            const listKey = getAFEventListKey(meta.arg);

            let eventList = state.eventListCache[listKey];
            if(!eventList) {
                eventList = {
                    isLoading: true
                }
            } else {
                eventList.isLoading = true;
            }
            state.eventListCache[listKey] = eventList;
        })
        builder.addCase(getEventList.fulfilled, (state, { meta, payload }) => {

            const listKey = getAFEventListKey(meta.arg);

            const {
                listtype, start, len
            } = meta.arg;

            const events = Array.isArray(payload.resultData) ? payload.resultData : [];
            const totalCount = payload.totalCount;

            let eventList = state.eventListCache[listKey];
            if(!eventList) {
                eventList = {
                    isLoading: false,
                    isEmpty: false
                }
            } else {

                const ids = eventList.ids ? eventList.ids : {};
                for(let i = 0 ; i < len ; i++) {
                    const event = events[i];

                    let index = start + i;
                    if(!event) {
                        delete ids[index]; // 移除不存在的內容
                        continue;
                    } 

                    const eventid = event.eventid.trim();
                    event.eventid = eventid;
                    event.isSubscribed = listtype === EVENT_LIST_TYPE.SUBSCRIBED; // 是否訂閱的判斷才端補上
                    ids[index] = eventid
                    state.eventCache[eventid] = event;
                }

                eventList.ids = ids;
                eventList.isLoading = false;
                eventList.isError = false;
                eventList.isEmpty = Object.keys(ids).length === 0;
                if(totalCount) {
                    eventList.totalCount = totalCount;
                }
            }

            state.eventListCache[listKey] = eventList;
        })
        builder.addCase(getEventList.rejected, (state, { meta }) => {

            const listKey = getAFEventListKey(meta.arg);

            let eventList = state.eventListCache[listKey];
            if(!eventList) {
                eventList = {
                    isLoading: false,
                    isError: true
                }
            } else {
                eventList.isLoading = false;
                eventList.isError = true;
            }

            state.eventListCache[listKey] = eventList;
        })
        
        builder.addCase(getEventInfo.fulfilled, (state, { payload }) => {
            const event = payload.resultData;
            const eventid = event.eventid.trim();
            payload.eventid = eventid;
            state.eventCache[eventid] = event;
        })
        builder.addCase(updateEvent.fulfilled, (state, { payload }) => {
            const event = payload.resultData;
            const eventid = event.eventid.trim();
            payload.eventid = eventid;
            state.eventCache[eventid] = event;
        })
        
        builder.addCase(subscribeEvent.fulfilled, (state, { meta }) => {

            const { 
                // afmemberid,
                eventid 
            } = meta.arg;

            const event = state.eventCache[eventid];
            if(event) {
                event.isSubscribed = true;
                state.eventCache[eventid] = event;
            }
        })

        builder.addCase(unsubscribeEvent.fulfilled, (state, { meta }) => {

            const { 
                // afmemberid,
                eventid 
            } = meta.arg;

            const event = state.eventCache[eventid];
            if(event) {
                event.isSubscribed = false;
                state.eventCache[eventid] = event;
            }
        })

        builder.addCase(addEventProduct.fulfilled, (state, { payload }) => {
            const event = payload.resultData;
            const eventid = event.eventid.trim();
            payload.eventid = eventid;
            state.eventCache[eventid] = event;
        })
        builder.addCase(removeEventProduct.fulfilled, (state, { payload }) => {
            const event = payload.resultData;
            const eventid = event.eventid.trim();
            payload.eventid = eventid;
            state.eventCache[eventid] = event;
        })
        builder.addCase(orderEventProducts.fulfilled, (state, { payload }) => {
            const event = payload.resultData;
            const eventid = event.eventid.trim();
            payload.eventid = eventid;
            state.eventCache[eventid] = event;
        })
        
    }
});

// actions
export const actions = eventSlice.actions;

// reducer
export default eventSlice.reducer;