/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-10-26 17:06:10
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2022-10-28 11:22:22
 * @FilePath: /webapp/components/event/AFEventUtil.jsx
 * @Description: AFEvent相關元件
 */

// List 
export const EVENT_LIST_TYPE = {
    SUBSCRIBED: 'subscribed', // 已訂閱
    UNSUBSCRIBED: 'unsubscribed', // 未訂閱
}

// 取得 List 對應的 Key
export const getAFEventListKey = (payload) => {

    const {
        afmemberid,
        listtype, // EVENT_LIST_TYPE
        keyword,
        start_yyyymmdd,
        end_yyyymmdd,
    } = payload;

    let listKey = '';

    if(afmemberid)
        listKey += `afmemberid-${afmemberid}`;
    if(listtype)
        listKey += `listtype-${listtype}`;
    if(keyword) 
        listKey += `keyword-${keyword}`;
    if(start_yyyymmdd) 
        listKey += `start_yyyymmdd-${start_yyyymmdd}`;
    if(end_yyyymmdd) 
        listKey += `end_yyyymmdd-${end_yyyymmdd}`;

    if(!listKey)
        return "ALL";
    
    return listKey;
}