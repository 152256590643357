/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-10-11 15:01:01
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2022-11-02 11:16:05
 * @FilePath: /Nonn_AFF2E/store/index.jsx
 * @Description: init Redux Store
 */


import { configureStore } from "@reduxjs/toolkit";
import webSlice from "./webSlice";
import adminSlice from './adminSlice';
import memberSlice from "./memberSlice";
import eventSlice from "./eventSlice";
import salesSlice from './salesSlice';

export const store = configureStore({
    reducer: {
        web: webSlice,
        admin: adminSlice,
        member: memberSlice,
        event: eventSlice,
        sales: salesSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
})

// const makeStore = () => store;

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
// export type AppStore = ReturnType<typeof makeStore>;
// export type AppState = ReturnType<AppStore['getState']>;
// export const wrapper = createWrapper(makeStore);