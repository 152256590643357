/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-10-28 15:43:38
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2023-03-10 10:59:41
 * @FilePath: /webapp/services/salesServices.js
 * @Description: 銷售相關 Services
 */
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from '../components/global';

// 取得銷售報表
export const getSalesReportList = createAsyncThunk(
    "/sales/getSalesReportList",
    async ({
        afmemberid,
        reportmonth,
        afmembername,
        start_yyyymmdd,
        end_yyyymmdd,
        start = 0,
        len = 20
    }, thunkAPI) => {

        try {
            
            let url = `${API_URL}afsales/queryreports?start=${start}&len=${len}`;
            if (afmemberid) url += `&afmemberid=${afmemberid}`;
            if (afmembername) url += `&afmembername=${afmembername}`;
            if (reportmonth) url += `&reportmonth=${reportmonth}`;
            if (start_yyyymmdd) url += `&start_yyyymmdd=${start_yyyymmdd}`;
            if (end_yyyymmdd) url += `&end_yyyymmdd=${end_yyyymmdd}`;

            const response = await axios({
                method: 'GET',
                url: url,
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 設定銷售分潤的結帳時間
export const settleAFSalesReport = createAsyncThunk(
    "/sales/settleAFSalesReport",
    async ({
        afmemberid,
        reportmonth,
    }, thunkAPI) => {

        try {
            
            const url = `${API_URL}afsales/settle/${afmemberid}/${reportmonth}`;

            const response = await axios({
                method: 'POST',
                url: url,
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)


// 取得銷售報表
export const getAFSalesLogList = createAsyncThunk(
    "/sales/getAFSalesLogList",
    async ({
        nonnmemberid,
        orderid,
        validflag = 999,
        aftoken,
        start_yyyymmdd,
        end_yyyymmdd,
        start = 0,
        len = 20
    }, thunkAPI) => {

        try {
            
            let url = `${API_URL}afsales/querylogs?start=${start}&len=${len}`;
            if (nonnmemberid) url += `&nonnmemberid=${nonnmemberid}`;
            if (orderid) url += `&orderid=${orderid}`;
            url += `&validflag=${validflag}`;
            if (aftoken) url += `&aftoken=${aftoken}`;
            if (start_yyyymmdd) url += `&start_yyyymmdd=${start_yyyymmdd}`;
            if (end_yyyymmdd) url += `&end_yyyymmdd=${end_yyyymmdd}`;

            const response = await axios({
                method: 'GET',
                url: url,
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const createSalesReport = createAsyncThunk(
    "/sales/createSalesReport",
    async ({
        reportmonth
    }, thunkAPI) => {

        try {
            
            const url = `${API_URL}afsales/createsalesreport`;

            const response = await axios({
                method: 'POST',
                url: url,
                data: {
                    reportmonth: reportmonth
                },
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)


export const cancelAFSalesLog = createAsyncThunk(
    "/sales/cancelAFSalesLog",
    async ({
        orderid
    }, thunkAPI) => {

        try {
            
            const url = `${API_URL}afsales/cancellog/${orderid}`;

            const response = await axios({
                method: 'GET',
                url: url,
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)