import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { getRouteData } from './routes';
import { RouteActionProps } from './@type/routes'


interface RouteLinkProps extends RouteActionProps {
    children: ReactElement,
    scroll?: boolean
}

const RouteLink = (props: RouteLinkProps) => {

    const {
        children,
        ...routeProps
    } = props;

    const {
        pathname = "/"
    } = getRouteData(routeProps);

    return (
        <Link to={pathname}>
            {children}
        </Link>
    )

}

export default RouteLink;