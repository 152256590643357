/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-10-11 14:51:35
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2022-12-02 15:33:06
 * @FilePath: /Nonn_AFF2E/components/commons/TopBar.jsx
 * @Description: 網站  Topbar
 */

import React from "react";
import { Menu } from '@mantine/core';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MdOutlineArrowDropDown, MdOutlineSettings, MdOutlinePowerSettingsNew } from 'react-icons/md';
import useLoginInfo from '../../hoosk/admin/useLoginInfo';
import { RoutePaths } from "../route/@type/routes.d";
import { getRouteData } from '../route/routes';
import RouteLink from '../route/RouteLink';

const Container = styled.header`
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    z-index: 100;
    
`;
const Logo = styled.img`
    position: relative;
    width: 150px;
    vertical-align: middle;
`;
const Title = styled.div`
    display: inline-block;
    margin: 0 0 0 10px;
    font-size: 18px;
    font-weight: 700;
`;
const LoginInfo = styled.div`
    margin: 0 20px 0 auto;
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
`;
const LoginAvatar = styled.div`
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin: 0 10px 0 0;
    background-color: #EEE;
    border: 1px solid #CCC;
    ${props => props.imageURL ? `background-image: url(${props.imageURL});` : ''}
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
`;

const TopBar = () => {

    const {
        isAdminLogin,
        adminInfo,
        isLogin,
        afMemberInfo,
        logout
    } = useLoginInfo();

    const navigate = useNavigate();

    const onEditClick = () => {

        if(isAdminLogin) {
            const { pathname } = getRouteData({
                path: RoutePaths.UPDATEADMIN,
                params: {
                    adminid: adminInfo.adminid
                }
            });
            navigate(pathname)
        } else if(afMemberInfo) {
            const { pathname } = getRouteData({
                path: RoutePaths.AFMEMBER_EDIT,
                params: {
                    afmemberid: afMemberInfo.afmemberid
                }
            });
            navigate(pathname);
        }
    }

    return (
        <Container>
            <RouteLink path={RoutePaths.HOME}>
                <>
                    <Logo src="/images/logo.png" alt="9199" />
                    <Title>
                        {isAdminLogin ? '9199 聯盟行銷 後台管理系統' : '9199 聯盟行銷 推廣者專頁'}
                    </Title>
                </>
            </RouteLink>
            {(isAdminLogin || isLogin) && (
                <Menu shadow="md" width={200}>
                    <Menu.Target>
                        <LoginInfo>
                            {isLogin && afMemberInfo && <LoginAvatar imageURL={afMemberInfo.photolink} />}
                            {isAdminLogin && adminInfo && adminInfo.adminid}
                            {isLogin && afMemberInfo && (afMemberInfo.membername || afMemberInfo.email)}
                            <MdOutlineArrowDropDown size={16} />
                        </LoginInfo>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item icon={<MdOutlineSettings size={16} />} onClick={onEditClick}>{isAdminLogin ? '設定' : '個人資訊'}</Menu.Item>
                        <Menu.Divider />
                        <Menu.Item color="red" icon={<MdOutlinePowerSettingsNew size={16} />} onClick={logout}>登出</Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            )}
            
        </Container>
    );
};

export default TopBar;
