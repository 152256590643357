/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-10-13 10:54:07
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2022-11-08 15:00:05
 * @FilePath: /webapp/components/route/@type/routes.d.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * Jcolor 路徑宣告
 */

export enum RoutePaths {
    HOME = "/", // 首頁
    
    AFMEMBERLIST = "/member/", // 推廣者列表
    AFMEMBER = "/member/:afmemberid", // 推廣者主頁
    AFMEMBER_MEMBERS = "/member/:afmemberid/members", // 推廣者主頁（有效用戶）
    AFMEMBER_SALESLOG = "/member/:afmemberid/saleslog", // 推廣者主頁（分潤明細）
    AFMEMBER_SALESREPORT = "/member/:afmemberid/salesreport", // 推廣者主頁（分潤對賬）
    AFMEMBER_UNSUBSCRIBEEVENT = "/member/:afmemberid/unsubscribe", // 推廣者主頁（尚未訂閱的活動）
    AFMEMBER_EDIT = "/member/:afmemberid/edit", // 推廣者主頁（編輯）
    ADDAFMEMBER = "/member/new", // 新增推廣者

    EVENTS = "/afevent/", // 推廣管理列表
    ADDNEWEVENT = "/afevent/new", // 新增推廣活動
    EDITEVENT = "/afevent/:eventid", // 編輯推廣活動
    EDITEVENT_PRODUCTS = "/afevent/:eventid/products", // 編輯推廣活動商品
    EVENT_PRODUCTS = '/afevent/:eventid/products/show', // 活動商品展現頁（提供第三方iframe）

    AFSALESQUERYLOG = "/afsales/logs", // 銷售明細表
    AFSALESQUERYREPORT = "/afsales/reports" ,//銷售分潤報表

    ADMIN = "/admin/", // 管理者列表
    ADMIN_LOGIN = '/admin/login', // 管理者登入
    ADDNEWADMIN = "/admin/new", // 新增管理者
    UPDATEADMIN = "/admin/:adminid", // 編輯、查看管理者
    
}


/**
 * RouteActionProps
 */
export interface RouteActionProps {
    path: RoutePaths | string,
    params?: { [propName: string]: string | number },
    search?: { [propName: string]: string | number | undefined },
    hash?: string,
    state?: { [propName: string]: any },
}