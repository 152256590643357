/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-10-11 14:51:35
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2023-03-29 10:32:41
 * @FilePath: /Nonn_AFF2E/components/commons/LoginView.jsx
 * @Description: 推廣者未登入的畫面
 */
import { useState } from 'react';
import { Button, Input, Loader, Alert } from "@mantine/core";
import styled from "styled-components";
import { MdOutlineAccountCircle, MdLockOutline, MdErrorOutline } from "react-icons/md";
import useLoginInfo from '../../hoosk/admin/useLoginInfo';
import ReCAPTCHA from "react-google-recaptcha";

/**
 * Styles
 */
const Container = styled.div`
    max-width: 400px;
    margin: 0 auto;
    padding: 60px 0;
`;
const Header = styled.div`
    background-color: #fd7e14;
    display: flex;
    align-items: center;
    height: 100px;
`;
const Logo = styled.img`
    position: relative;
    width: 110px;
    margin: 0 auto;
`;
const Title = styled.h1`
    margin: 20px auto;
    font-weight: 700;
    text-align: center;
    font-size: 18px;
    color: #FFF;
`;
const Content = styled.div`
    margin: 0 auto;
    width: 100%;
    /* border: 1px solid #CCC; */
    background-color: #FFF;
    padding: 40px;
`;
const FormRow = styled.div`
    margin: 20px auto 0;
    &:first-of-type {
        margin: 0;
    }
`;
const ActionBar = styled.div`
    margin: 20px 0 0;
    text-align: center;
    button {
        width: 100%;
    }
`; 
// const ForgetPasswd = styled.div`
//     margin: 10px 0 0;
//     font-size: 14px;
//     text-align: center;
//     color: #8A807F;
//     text-decoration: underline;
//     cursor: pointer;
// `;

const LoginView = (props) => {

    const {
        isLogining,
        loginError,
        afMemberLogin
    } = useLoginInfo();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState(undefined);
    const [emailError, setEmailError] = useState(undefined);
    const [passwordError, setPasswordError] = useState(undefined);
    const [tokenError, setTokenError] = useState(undefined);

    const onEmailChangeHandler = (e) => {
        setEmail(e.target.value);
    }
    
    const onPasswordChangeHandler = (e) => {
        setPassword(e.target.value);
    }

    const onPasswordKeyPress = (e) => {

        if(e.target.value && e.key === 'Enter') {
            onSubmit();
        }
    }

    const onTokenChange = (value) => {
        setToken(value);
    }

    const onSubmit = () => {

        let _emailError = '';
        let _passwordError = '';
        let _tokenError = '';
        if(!email) {
            _emailError = '請輸入電子郵件';
        }
        if(!password) {
            _passwordError = '請輸入帳號';
        }
        if(!token) {
            _tokenError = '請確認你不是機器人'
        }

        setEmailError(_emailError);
        setPasswordError(_passwordError);
        setTokenError(_tokenError);
        if(_emailError || _passwordError || _tokenError) {
            return;
        }

        afMemberLogin(email, password, token);
    }
    
    return (
        <Container>
            <Header>
                <Logo src="/images/logo.png" alt="9199" />
                <Title>
                    9199 聯盟行銷 推廣者專頁
                </Title>
            </Header>
            <Content>
                <FormRow>
                    <Input.Wrapper
                        label="電子郵件"
                        error={emailError}
                    >
                        <Input
                            icon={<MdOutlineAccountCircle />}
                            placeholder="請輸入您的電子郵件" 
                            value={email}
                            onChange={onEmailChangeHandler}
                            type="email"
                        />
                    </Input.Wrapper>
                </FormRow>
                <FormRow>
                    <Input.Wrapper
                        label="密碼"
                        error={passwordError}
                    >
                        <Input
                            icon={<MdLockOutline />}
                            type="password"
                            placeholder="請輸入您的密碼" 
                            value={password}
                            onChange={onPasswordChangeHandler}
                            onKeyPress={onPasswordKeyPress}
                        />
                    </Input.Wrapper>
                </FormRow>
                <FormRow>
                    <ReCAPTCHA
                        sitekey="6LfJa3EUAAAAAEuCCizjmVAHzKAVP50Rn6yWCyrI"
                        onChange={onTokenChange}
                    />
                    <Input.Wrapper
                        error={tokenError}
                    />
                </FormRow>
                <ActionBar>
                    <Button size="md" color="orange" onClick={onSubmit}>
                        {isLogining && <Loader color="#FFF" size={16} />}
                        登入
                    </Button>
                </ActionBar>
                {/* <ForgetPasswd>
                    忘記密碼
                </ForgetPasswd> */}

                {loginError.code && (
                    <Alert icon={<MdErrorOutline />} title={loginError.resultMsg} color="red">
                        {loginError.field_message.length > 0 && loginError.field_message.join(',')}
                    </Alert>
                )}
            </Content>
        </Container>
    );
};
export default LoginView;
