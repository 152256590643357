/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-10-28 15:42:50
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2023-02-18 10:38:50
 * @FilePath: /webapp/store/salesSlice.jsx
 * @Description: 銷售 Slice
 */
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { 
    getSalesReportList,
    settleAFSalesReport,
    getAFSalesLogList,
    cancelAFSalesLog,
} from '../services/salesServices';
import { getAFSalesReportListKey, getAFSalesLogListKey, SALESLOG_VALIDFLAG } from '../components/sales/AFSalesUtil';

// init state
const initialState = {
    reportListCache: { // Report List Cache
        // [listkey]: {
        //      ids: { [index]: ${afmemberid}-${reportmonth} }
        //      totalCount
        //      isLoading
        //      isError
        // }
    }, 
    reportCache: { // Report Info Cache
        // [${afmemberid}-${reportmonth}]: AFEvent
    },
    logListCache: { // Sale Log List Cache
        // [listkey]: {
        //      ids: { [index]: orderid }
        //      totalCount
        //      isLoading
        //      isError
        // }
    }, 
    logCache: { // Sale Log Info Cache
        // [${afmemberid}-${reportmonth}]: AFEvent
    },
}

// creator
export const salesSlice = createSlice({
    name: 'sales',
    initialState: initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder.addCase(getSalesReportList.pending, (state, { meta, payload }) => {

            const listKey = getAFSalesReportListKey(meta.arg);

            let reportList = state.reportListCache[listKey];
            if(!reportList) {
                reportList = {
                    isLoading: true
                }
            } else {
                reportList.isLoading = true;
            }
            state.reportListCache[listKey] = reportList;
        })
        builder.addCase(getSalesReportList.fulfilled, (state, { meta, payload }) => {

            const listKey = getAFSalesReportListKey(meta.arg);

            const {
                start, len
            } = meta.arg;

            const reports = Array.isArray(payload.resultData) ? payload.resultData : [];
            const totalCount = payload.totalCount;

            let reportList = state.reportListCache[listKey];
            if(!reportList) {
                reportList = {
                    isLoading: false,
                    isEmpty: false
                }
            } else {

                const ids = reportList.ids ? reportList.ids : {};
                for(let i = 0 ; i < len ; i++) {
                    const report = reports[i];

                    let index = start + i;
                    if(!report) {
                        delete ids[index]; // 移除不存在的內容
                        continue;
                    } 

                    const afmemberid = report.afmemberid.trim();
                    const reportmonth = report.reportmonth.trim();
                    const reportid = `${afmemberid}-${reportmonth}`;
                    report.reportid = reportid;
                    report.afmemberid = afmemberid;
                    report.reportmonth = reportmonth;
                    ids[index] = reportid
                    state.reportCache[reportid] = report;
                }

                reportList.ids = ids;
                reportList.isLoading = false;
                reportList.isError = false;
                reportList.isEmpty = Object.keys(ids).length === 0;
                if(totalCount) {
                    reportList.totalCount = totalCount;
                }
            }

            state.reportListCache[listKey] = reportList;
        })
        builder.addCase(getSalesReportList.rejected, (state, { meta, payload }) => {

            const listKey = getAFSalesReportListKey(meta.arg);

            let reportList = state.reportListCache[listKey];
            if(!reportList) {
                reportList = {
                    isLoading: false,
                    isError: true
                }
            } else {
                reportList.isLoading = false;
                reportList.isError = true;
            }

            state.reportListCache[listKey] = reportList;
        })

        builder.addCase(settleAFSalesReport.fulfilled, (state, { meta, payload }) => {

            const {
                afmemberid,
                reportmonth
            } = meta.arg;

            const reportid = `${afmemberid}-${reportmonth}`;
            const report = state.reportCache[reportid];
            if(report) {

                const dayobj = dayjs(new Date());
                report.settledate = dayobj.format("YYYYMMDD");

                state.reportCache[reportid] = report;
            }
        })


        builder.addCase(getAFSalesLogList.pending, (state, { meta, payload }) => {

            const listKey = getAFSalesLogListKey(meta.arg);

            let logList = state.logListCache[listKey];
            if(!logList) {
                logList = {
                    isLoading: true
                }
            } else {
                logList.isLoading = true;
            }
            state.logListCache[listKey] = logList;
        })
        builder.addCase(getAFSalesLogList.fulfilled, (state, { meta, payload }) => {

            const listKey = getAFSalesLogListKey(meta.arg);

            const {
                start, len
            } = meta.arg;

            const logs = Array.isArray(payload.resultData) ? payload.resultData : [];
            const totalCount = payload.totalCount;


            let logList = state.logListCache[listKey];
            if(!logList) {
                logList = {
                    isLoading: false,
                    isEmpty: false
                }
            } else {

                const ids = logList.ids ? logList.ids : {};
                for(let i = 0 ; i < len ; i++) {
                    const log = logs[i];

                    let index = start + i;
                    if(!log) {
                        delete ids[index]; // 移除不存在的內容
                        continue;
                    } 

                    const orderid = log.orderid;
                    log.orderid = orderid;
                    ids[index] = orderid
                    state.logCache[orderid] = log;
                }

                logList.ids = ids;
                logList.isLoading = false;
                logList.isError = false;
                logList.isEmpty = Object.keys(ids).length === 0;
                if(totalCount) {
                    logList.totalCount = totalCount;
                }
            }

            state.logListCache[listKey] = logList;
        })
        builder.addCase(getAFSalesLogList.rejected, (state, { meta, payload }) => {

            const listKey = getAFSalesLogListKey(meta.arg);

            let logList = state.logListCache[listKey];
            if(!logList) {
                logList = {
                    isLoading: false,
                    isError: true
                }
            } else {
                logList.isLoading = false;
                logList.isError = true;
            }

            state.logListCache[listKey] = logList;
        })

        builder.addCase(cancelAFSalesLog.fulfilled, (state, { meta, payload }) => {

            const {
                orderid
            } = meta.arg;

            const salesLog = state.logCache[orderid];
            if(!salesLog)
                return;
            
            salesLog.validflag = SALESLOG_VALIDFLAG.INVALID;
            state.logCache[orderid] = salesLog;
        })
        
    }
});

// actions
export const actions = salesSlice.actions;

// reducer
export default salesSlice.reducer;