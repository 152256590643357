/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-10-17 10:24:41
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2023-03-29 10:35:38
 * @FilePath: /webapp/services/adminServices.ts
 * @Description: Admin Service
 */
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from '../components/global';
import { actions as admimActions } from '../store/adminSlice';

// 檢查管理者是否有登入
export const checkAdminLogin = createAsyncThunk(
    "/admin/checkLogin",
    async (
        arg,    
        thunkAPI
    ) => {

        try {
            const url = `${API_URL}admin/checkLogin`;
            const response = await axios({
                method: 'GET',
                url: url,
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            thunkAPI.dispatch(admimActions.setAdminLoginInfo(data.resultData));

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 管理者登入
export const login = createAsyncThunk(
    "/admin/login",
    async ({
        adminid,
        password,
        token
    }, thunkAPI) => {

        try {
            const url = `${API_URL}admin/loginAction`;
            const response = await axios({
                method: 'POST',
                url: url,
                data: {
                    adminid,
                    password,
                    token
                },
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }
            
            thunkAPI.dispatch(checkAdminLogin());

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)


// 管理者登出
export const logout = createAsyncThunk(
    "/admin/logout",
    async (arg, thunkAPI) => {

        try {
            const url = `${API_URL}admin/logout`;
            const response = await axios({
                method: 'POST',
                url: url,
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 取得單一個管理者的資訊
export const getAdminInfo = createAsyncThunk(
    "/admin/getAdminInfo",
    async (
        adminid,    
        thunkAPI
    ) => {

        try {
            const url = `${API_URL}admin/get/${adminid}`;
            const response = await axios({
                method: 'GET',
                url: url,
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 更新管理者密碼
export const updateAdminPassword = createAsyncThunk(
    "/admin/updatePassword",
    async ({
        adminid,
        password
    }, thunkAPI) => {

        try {
            const url = `${API_URL}admin/update`;
            const response = await axios({
                method: 'POST',
                url: url,
                headers: { "Content-Type": "multipart/form-data" },
                data: {
                    adminid,
                    password
                },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 更新管理者狀態
export const updateAdminAuthLevel = createAsyncThunk(
    "/admin/updateAuthLevel",
    async ({
        adminid,
        authlevel
    }, thunkAPI) => {

        try {
            const url = `${API_URL}admin/toggle/${adminid}/${authlevel}`;
            const response = await axios({
                method: 'POST',
                url: url,
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 取得所有的管理者列表
export const getAllAdmins = createAsyncThunk(
    "/admin/getAllAdmins",
    async (
        arg,    
        thunkAPI
    ) => {

        try {
            const url = `${API_URL}admin/query`;
            const response = await axios({
                method: 'GET',
                url: url,
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// 建立管理者
export const createAdmin = createAsyncThunk(
    "/admin/create",
    async ({
        adminid,
        password
    }, thunkAPI) => {

        try {
            const url = `${API_URL}admin/create`;
            const response = await axios({
                method: 'POST',
                url: url,
                data: {
                    adminid,
                    password
                },
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            });
            const data = response.data;

            if(data && data.code !== 'result.ok') {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error){
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)