/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-10-31 11:36:30
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2023-03-10 10:59:20
 * @FilePath: /webapp/components/sales/AFSalesUtil.jsx
 * @Description: Sales Util
 */

// 取得 List 對應的 Key
export const getAFSalesReportListKey = (payload) => {

    const {
        afmemberid,
        reportmonth,
        afmembername,
        start_yyyymmdd,
        end_yyyymmdd,
    } = payload;

    let listKey = '';

    if(afmemberid)
        listKey += `afmemberid-${afmemberid}`;
    if(afmembername)
        listKey += `afmembername-${afmembername}`;
    if(reportmonth)
        listKey += `reportmonth-${reportmonth}`;
    if(start_yyyymmdd) 
        listKey += `start_yyyymmdd-${start_yyyymmdd}`;
    if(end_yyyymmdd) 
        listKey += `end_yyyymmdd-${end_yyyymmdd}`;

    if(!listKey)
        return "ALL";
    
    return listKey;
}

// 取得 List 對應的 Key
export const getAFSalesLogListKey = (payload) => {

    const {
        nonnmemberid,
        orderid,
        validflag,
        aftoken,
        start_yyyymmdd,
        end_yyyymmdd,
    } = payload;

    let listKey = '';

    if(nonnmemberid)
        listKey += `nonnmemberid-${nonnmemberid}`;
    if(orderid)
        listKey += `orderid-${orderid}`;
    if(validflag)
        listKey += `validflag-${validflag}`;
    if(aftoken)
        listKey += `aftoken-${aftoken}`;
    if(start_yyyymmdd) 
        listKey += `start_yyyymmdd-${start_yyyymmdd}`;
    if(end_yyyymmdd) 
        listKey += `end_yyyymmdd-${end_yyyymmdd}`;

    if(!listKey)
        return "ALL";
    
    return listKey;
}

// 訂單狀態
export const SALESLOG_VALIDFLAG = {
    UNDEFINED: 0, // 訂單尚未成立(72小時內)
    VALID: 1, // 訂單成立(超過72小時未被否決)
    INVALID: -1, // 訂單不成立(72小時內被否決)
}