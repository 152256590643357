/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-10-11 15:08:31
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2022-10-18 17:16:58
 * @FilePath: /Nonn_AFF2E/store/adminSlice.jsx
 * @Description: Admin Slice
 */

import { createSlice } from "@reduxjs/toolkit";
import { 
    checkAdminLogin,
    login,
    logout,
    getAdminInfo,
    updateAdminAuthLevel,
    getAllAdmins,
} from '../services/adminServices';

// init state
const initialState = {
    isCheckingLogin: false, // 是否正在檢查管理者登入
    isLogining: false, // 管理者是否正在登入
    loginError: {
        code: undefined,
        resultMsg: undefined,
        field_message: []
    }, // 管理者登入錯誤訊息
    isLogin: false, // 是否已經登入
    admin: undefined, // 管理者資訊
    isLogouting: false, // 是否正在登出中,
    isLoadingList: false,
    adminListCache: { // Admin List Cache
        // [index]: adminid
    }, 
    adminCache: { // Admin Info Cache
        // [adminid]: adminInfo
    },
}

// creator
export const adminSlice = createSlice({
    name: 'admin',
    initialState: initialState,
    reducers: {
        setAdminLoginInfo: (state, { payload }) => {
            state.isLogin = true;
            const adminid = payload.adminid.trim();
            payload.adminid = adminid;
            state.admin = payload;
            state.adminCache[adminid] = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(checkAdminLogin.pending, (state) => {
            state.isCheckingLogin = true;
        })
        builder.addCase(checkAdminLogin.fulfilled, (state) => {
            state.isCheckingLogin = false;
        })
        builder.addCase(checkAdminLogin.rejected, (state) => {
            state.isCheckingLogin = false;
        })
        builder.addCase(login.pending, (state) => {
            state.isLogining = true;
            state.loginError.code = undefined;
            state.loginError.resultMsg = undefined;
            state.loginError.field_messageMap = undefined;
        })
        builder.addCase(login.fulfilled, (state) => {
            state.isLogining = false;
        })
        builder.addCase(login.rejected, (state, { payload }) => {
            state.isLogining = false;
            if(payload) {
                state.loginError.code = payload.code;
                state.loginError.resultMsg = payload.resultMsg;
                const field_message = [];
                if(payload.field_messageMap) {
                    for(let key in payload.field_messageMap) {
                        field_message.push(payload.field_messageMap[key]);
                    }
                }
                state.loginError.field_message = field_message;
            }
        })
        builder.addCase(logout.pending, (state) => {
            state.isLogouting = true;
        })
        builder.addCase(logout.fulfilled, (state) => {
            state.isLogouting = false;
            state.isLogin = false;
            state.admin = undefined;
        })
        builder.addCase(logout.rejected, (state, result) => {
            state.isLogouting = false;
        })

        builder.addCase(getAdminInfo.fulfilled, (state, { payload }) => {
            const admin = payload.resultData;
            const adminid = admin.adminid.trim();
            payload.adminid = adminid;
            state.adminCache[adminid] = admin;
        })

        builder.addCase(updateAdminAuthLevel.fulfilled, (state, { meta, payload }) => {

            const {
                adminid,
                authlevel
            } = meta.arg;

            const admin = state.adminCache[adminid];
            if(!admin)
                return;
            
            admin.authlevel = authlevel;
            state.adminCache[adminid] = admin;
        })

        builder.addCase(getAllAdmins.pending, (state) => {
            state.isLoadingList = true;
        })
        builder.addCase(getAllAdmins.fulfilled, (state, { payload }) => {
            
            if(Array.isArray(payload.resultData) && payload.resultData.length > 0) {
                for(let i = 0 ; i < payload.resultData.length ; i++) {
                    const admin = payload.resultData[i];
                    const adminid = admin.adminid.trim();
                    admin.adminid = adminid;
                    state.adminCache[adminid] = admin;
                    state.adminListCache[i] = adminid;
                }
            }
            state.isLoadingList = false;
        })
        builder.addCase(getAllAdmins.rejected, (state) => {

            state.isLoadingList = false;
        })
    }
});

// actions
export const actions = adminSlice.actions;

// reducer
export default adminSlice.reducer;