/*
 * @Author: alan.cheng alan.cheng@ilinke.com
 * @Date: 2022-10-17 10:14:54
 * @LastEditors: alan.cheng alan.cheng@ilinke.com
 * @LastEditTime: 2022-11-08 15:06:05
 * @FilePath: /webapp/components/global.js
 * @Description: 各種共用屬性
 */

// ------------------------------------------
// API URL
// ------------------------------------------

// API Root Path
// export const API_URL = '/Nonn_AF/'; // 使用Local測試環境要看每個人的專案名稱叫什麼
export const API_URL = '/'; // 正式環境不需要 prefix
