import { RouteActionProps } from "./@type/routes.d";

export const getRouteData = (
    props: RouteActionProps
): {
    pathname: string;
    search?: string;
    hash?: string;
    state?: { [propName: string]: any };
} => {
    const { path, params, search, hash, state } = props;

    const searchString: string | undefined = search
        ? Object.keys(search).reduce((acc, key, index) => {
              if (!search[key]) {
                  return acc;
              }

              if (index === 0) {
                  acc += "?";
              } else {
                  acc += "&";
              }

              acc += `${key}=${search[key]}`;

              return acc;
          }, "")
        : void 0;
        
    return {
        pathname: params
            ? Object.keys(params).reduce((acc, key) => {
                  const match = `:${key}`;

                  if (acc.includes(match)) {
                      return acc.replace(match, `${params[key]}`);
                  }

                  return acc;
              }, path)
            : path,
        search: searchString,
        hash: hash ? "#hash" : void 0,
        state,
    };
};